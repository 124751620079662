<template>
    <v-col cols="12" class="mt-6 pt-6">
        <v-overlay :value="overlay" absolute>
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-btn class="mb-2" color="warning" @click="dialogUploadImg=true">
            <v-icon >mdi-cloud-upload</v-icon> CARGAR IMÁGENES
        </v-btn>
        <v-btn class="mb-2 ml-6" color="primary" @click="dialog=true">
            <v-icon >mdi-file-image</v-icon> VER REFERENCIA
        </v-btn>
        <div class="py-3">
            <em> Nota: Cargar imágenes de  <b>1440px X 400px</b></em>
        </div>
        <v-alert
            color="cyan"
            border="left"
            elevation="2"
            colored-border
            class="mt-3"
        >
                <template v-slot:prepend>
                    <v-icon>mdi-image-multiple</v-icon> PREVISUALIZACIÓN
                </template>
        </v-alert>
        <v-carousel v-model="model">
            <v-carousel-item
                v-for="(img, i) in images.filter(e => e.status_id =='CTNT_PUBLISHED')"
                :key="i"
            >
                <v-sheet>
                    <v-img :src="`${domain}img/web_commerce/${img.content_name}`" />
                </v-sheet>
            </v-carousel-item>
        </v-carousel>
        <v-alert
            color="cyan"
            border="left"
            elevation="2"
            colored-border
            class="mt-3"
        >
                <template v-slot:prepend>
                    <v-icon>mdi-apps</v-icon> CONFIGURAR IMÁGENES
                </template>
        </v-alert>
        <draggable tag="div" class="grid_draggable" @end="endDrag">
            <v-col 
                v-for="(img, i) in images"
                :key="i"
            >
                <div class="d-flex justify-space-between">
                    <div>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn                                        
                                    :color="img.status_id == 'CTNT_PUBLISHED' ? 'success' : 'error'" 
                                     
                                    fab
                                    x-small
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="statusImgSliderWeb(img)"
                                >
                                    <v-icon>{{img.status_id == 'CTNT_PUBLISHED' ? 'mdi-eye' : 'mdi-eye-off'}}</v-icon>
                                </v-btn>
                            </template>
                            <span>{{img.status_id == 'CTNT_PUBLISHED' ? 'Publicado' : 'Despublicado'}}</span>
                        </v-tooltip>
                    </div>
                    <div>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    color="error" 
                                    @click="deleteImgSliderWeb(img)"
                                    fab
                                    x-small
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    <v-icon>mdi-delete</v-icon>
                                </v-btn>
                            </template>
                            <span>Eliminar</span>
                        </v-tooltip>
                    </div>
                    </div>
                <v-card tile elevation="0" class="personal" :data-order="`${img.order}`"> 
                    <v-img
                        :id="`item_${i}`"
                        :src="`${domain}img/web_commerce/${img.content_name}`"
                        class="grey darken-4"
                    ></v-img>
                </v-card>
            </v-col>
        </draggable>
        <v-dialog
            v-model="dialog" 
            persistent
        >
            <v-img :src="require('@/assets/images/slider-principal.png')" />
            <v-btn block @click="dialog=false">CERRAR</v-btn>
        </v-dialog>
        <v-dialog
            v-model="dialogUploadImg" 
            persistent
            width="600px"
        >
            <v-card>
				<v-card-title>
				    <span class="headline">Cargar imágenes</span>
				</v-card-title>
				<v-form ref="form_files">
					<v-card-text class="pb-0">
					<v-container fluid>
						<v-row class="py-0">
						<v-col cols="12" class="py-0" >
							<v-file-input
								label="Seleccione los archivos"
                                v-model="dataFile"
								outlined
								dense
								clearable
								multiple
                                accept=".jpg,.png,.JPG,.PNG"
								prepend-icon="mdi-folder-multiple-image"
								@change="setFile"
								:rules=requiredRule
							></v-file-input>
						</v-col>
						</v-row>
					</v-container>
					</v-card-text>
                    <v-col cols="12" class="pt-0 text-center">
                        <v-btn
                            color="primary"
                            class="mx-2"
                            @click="uploadFiles"
                            :loading="loadingBtn"
                        >  <v-icon>mdi-upload</v-icon> Cargar
                        </v-btn>
                        <v-btn
                            color="secondary"
                            class="mx-2"
                            
                            @click="close"
                        > <v-icon>mdi-cancel</v-icon> Cancelar
                        </v-btn>
                    </v-col>
				</v-form>
			</v-card>
        </v-dialog>
    </v-col>
</template>

<script>

    import {mapState, mapMutations, mapActions} from 'vuex'
    import axios from 'axios'
    import Vue from 'vue'
    import draggable from 'vuedraggable'

    export default {
        name:'SliderHomeEcommerce',
        components: {
            draggable,
        },
        data:()=>({
            model: 0,
            dialog:false,
            dialogUploadImg:false,
            overlay:false,
            dataFile:null,
            domain: process.env.VUE_APP_NODE_ENV === "production" ? process.env.VUE_APP_API_URL_PROD : process.env.VUE_APP_API_URL_DEVEL,
            images:[],
            requiredRule:[
                v => !!v || 'El campo es requerido'
            ],
            files:[],
        }),
        computed: {

            ...mapState('master',['token','url','loadingBtn','paramAlertQuestion'])

        },
        methods: {

            ...mapActions('master',['errorRequest','requestApi','alertNotification']),

            ...mapMutations('master',['setUrl','setLoadingBtn','setTitleToolbar']),

            setFile(event){
                this.files = typeof event != 'undefined' ? event : []
            },
          
            uploadFiles(){
                
                if(!this.$refs.form_files.validate())
				    return

                this.setLoadingBtn(true)
			
                this.setUrl('administracion')
                
                let formData = new FormData();

                this.files.map((file,x)=>{ 
                    formData.append(x,file) 
                })
                formData.append('typeAction', 'storeImgSliderWeb')
                formData.append('urlFiles', process.env.VUE_APP_API_URL_FILES_IMG_WEBCOMMERCE)
               
                axios.post(
                    this.url,
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': 'Bearer '+ this.token
                        }
                    }
                ).then(res=>{
                    console.log(res)
   
                    this.dialogUploadImg= false
                    this.getImgSlider()
                    this.alertNotification({
                        param:{
                            html: res.data.res.msg
                        }
                    })
                    
                    this.files= []

                    this.setLoadingBtn(false)

                }).catch(err =>{

                    this.dialogUploadImg= false
                    console.log(err)
                    const {status} = err.response;
                    if(status === 500 || status === 422){
                        let response = err.response;
                        this.errorRequest({
                            data : {
                                datos: response.data.errors,
                                status : status,
                                response: response
                            }
                        })
                    }
                    this.setLoadingBtn(false)

                }).then(()=>{
                    this.setLoadingBtn(false)
                })

            },

            close () {

                this.dialogUploadImg = false
                this.files=[]
                this.dataFile=null
            },

            getImgSlider(){              
    
                this.setUrl('administracion')
                this.requestApi({
                    method: 'GET',
                    data :{
                        typeList: 'dataWeb',
                        all: true
                    }
                }).then(res =>{

                    console.log(res)
                    this.images= res.data._embedded.administracion.filter(e => e.content_type_id =='IMG_SLIDER_WEB')

                }).then(()=>{
                    
                })
                
            },

            statusImgSliderWeb(item){

                Vue.swal({
                    html: "Está seguro de actualizar el estado de la imágen?",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Aceptar',
                    cancelButtonText: 'Cerrar',
                    ...this.paramAlertQuestion
                }).then( result => {

                    if (result.isConfirmed) {

                        this.overlay=true
                        this.setUrl('administracion')
                        this.requestApi({
                            method: 'POST',
                            data :{
                                typeAction: 'statusContent',
                                contentId: item.content_id
                            }
                        }).then(res =>{

                            this.getImgSlider()
                            this.alertNotification({
                                param:{
                                    html: res.data.res.msg
                                }
                            })
                            this.overlay=false

                        }).catch(()=>{
                            
                            this.overlay=false
                            const {status} = err.response
                            if(status === 500 || status === 422){
                                let response = err.response;
                                this.errorRequest({
                                    data : {
                                        datos: response.data.errors,
                                        status : status,
                                        response: response
                                    }
                                })
                            }

                        })

                    }

                })

            },

            endDrag(){

                this.overlay=true
                document.getElementsByClassName('personal').forEach((ele,i) => {
                    console.log( this.images, ele.dataset.order)
                    this.images[ele.dataset.order].order = i
                })

                let newOrder = this.images.map(obj =>{
                    return {
                        order: obj.order,
                        content_id: obj.content_id
                    }
                })
                
                this.setUrl('administracion')
                this.requestApi({
                    method: 'POST',
                    data :{
                        typeAction: 'reOrderContent',
                        newOrder
                    }
                }).then(res =>{

                    this.alertNotification({
                        param:{
                            html: res.data.res.msg
                        }
                    })
                    this.overlay=false

                }).catch(()=>{

                    this.overlay=false
                    const {status} = err.response
                    if(status === 500 || status === 422){
                        let response = err.response;
                        this.errorRequest({
                            data : {
                                datos: response.data.errors,
                                status : status,
                                response: response
                            }
                        })
                    }

                })

            },

            deleteImgSliderWeb(item){

                Vue.swal({
                    html: "Está seguro de eliminar la imágen?",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Aceptar',
                    cancelButtonText: 'Cerrar',
                    ...this.paramAlertQuestion
                }).then( result => {

                    if (result.isConfirmed) {

                        this.overlay=true
                        this.setUrl('administracion')
                        this.requestApi({
                            method: 'POST',
                            data :{
                                typeAction: 'deleteContent',
                                contentId: item.content_id,
                                contentTypeId: 'IMG_SLIDER_WEB'
                            }
                        }).then(res =>{

                            this.getImgSlider()
                            this.alertNotification({
                                param:{
                                    html: res.data.res.msg
                                }
                            })
                            this.overlay=false

                        }).catch(()=>{
                            
                            this.overlay=false
                            const {status} = err.response
                            if(status === 500 || status === 422){
                                let response = err.response;
                                this.errorRequest({
                                    data : {
                                        datos: response.data.errors,
                                        status : status,
                                        response: response
                                    }
                                })
                            }

                        })

                    }

                })

            }

        },
        created(){

            this.getImgSlider()
            this.setTitleToolbar('BANNERS PRINCIPALES')

        }
    }

</script>

<style>

.grid_draggable{
    flex-wrap: wrap;
    display: flex;
    
}

.grid_draggable > .col{
    width: 25%!important;
   max-width: 25%!important;
    
}

@media(max-width: 810px){
    .grid_draggable > .col{
        width: 50%!important;
        max-width: 50%!important;
    }
}

@media(max-width: 550px){
    .grid_draggable > .col{
        width: 100%!important;
        max-width: 100%!important;
    }
}


</style>